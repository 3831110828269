import { ApplicationContainer } from "@components/application-container";
import { ErrorBoundary } from "@components/error-boundary";

export interface WithApplicationProps {
  applicationId?: number;
}

export const withApplication = <P extends WithApplicationProps>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => (
    <ErrorBoundary>
      <ApplicationContainer applicationId={props?.applicationId}>
        <Component {...props} />
      </ApplicationContainer>
    </ErrorBoundary>
  );
};
